import "./ScrollIcon.css";

import React from "react";

// import IconScroll from "./iconScroll.svg"
import IconDesplegable from "../../../svg/icons/iconDesplegable.svg";

export default function ScrollIcon({ scrollText }) {
	return (
		<div id="iconScroll">
			<a href="#sectionContent2">
				{scrollText ? <h3>{scrollText}</h3> : null}

				{/* <IconScroll /> */}
				<IconDesplegable />
			</a>
			<div id="sectionContent" />
		</div>
	);
}
