import * as React from "react";

import "./SectionLanding.css";

import { Parallax } from "react-scroll-parallax";
import ScrollIcon from "./ScrollIcon/ScrollIcon";

export default function SectionLanding(props) {
	return (
		<Parallax speed={20} className="parallax">
			<div className="sectionLanding">
				<div>
					<h2>{props.orbitalText}</h2>

					<a href="#sectionContent2" className="sectionLandingTitle">
						<h1>{props.title}</h1>
					</a>

					<h2>{props.subtitle}</h2>
				</div>
				{props.children}
				{!props.onlyLanding && !props.hideScroll && (
					<ScrollIcon scrollText={props.scrollText} />
				)}
			</div>
		</Parallax>
	);
}
